import React, { Fragment } from "react"
import { injectIntl } from "gatsby-plugin-react-intl"
import HeroSlider from "components/HeroSlider"
import {
    Spacing,
    SharedStyles,
    Container,
    ButtonPlay,
} from "../../../kenra-storybook/index"
const { StLinkMore } = SharedStyles
import CustomLink from 'components/Link'
import styled, { css } from "styled-components"
import settings from "../../../kenra-storybook/global/settings"
import { Testimonials } from './Testimonials'
import '../../../fonts/fonts.css'
import { ProductImages } from './productMobileCard'

const blue = '#72879C'
const peach_light = '#CAA08E'
const peach_dark = '#BA9286'
const pink = '#B47E99'

const productColors = [
    blue,
    peach_light,
    peach_dark,
    pink
]

const MobileOnly = styled.div`
    display: block;

    @media (min-width: ${settings.bp.small.view}) {
        display: none;
    }
`

const DesktopOnly = styled.div`
    display: none;

    @media (min-width: ${settings.bp.small.view}) {
        display: flex;
    }
`

const SectionTitle = styled.div`
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 2px;
    line-height: 112%;
    margin: auto;
    display: flex;
    text-align: center;
    justify-content: center;

    @media (min-width: ${settings.bp.small.view}) {
        font-size: 24px;
        line-height: 40px;
    }
`

const VideoContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 100%;

    @media (min-width: ${settings.bp.small.view}) {
        flex-direction: row;
        justify-content: center;
        margin: auto;
        flex-wrap: wrap;
        margin-bottom: 100px;
    }
`

const VideoItem = styled.div`
   display: flex;
   width: 100%;
   padding: 10px 0;

    @media (min-width: ${settings.bp.small.view}) {
        min-width: 50%;
        max-width: 50%;
        padding: 12px;
        border-radius: 12px;
        flex-wrap: wrap;
    }
`

const VideoItemImage = styled.img`
    @media (min-width: ${settings.bp.small.view}) {
        border-radius: 12px;
    }
`

export const ItemImageWrapper = styled.div.attrs(props => ({
    className: 'ItemImageWrapper',
}))`
    
    max-height: 30vw;


    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    display: none;



    @media (min-width: ${settings.bp.small.view}) {
        display: block;
    }

    object-fit: contain;

    & img:nth-child(2) {
        display: none;
        position: absolute;
        left: ${(props) => (props.index % 2 === 0 ? '0' : 'auto')};
        right: ${(props) => (props.index % 2 === 0 ? 'auto' : '0')};
        top: 0;
    }

    &:nth-child(2n) img {
        float: right;
    }

    @media (min-width: ${settings.bp.small.view}) {
        &:hover {
            & img:nth-child(2) {
                display: block;
            }
        }
    }
    
`

const ProductContainer = styled.div`
    min-height: 30vw;
    max-height: 30vw;
    max-width: 37%;
    min-width: 37%;
    background-color: ${props => props.color};
    border-top-right-radius: ${props => (props.index % 2 === 0 ? '12px' : '0px')};
    border-bottom-right-radius: ${props => (props.index % 2 === 0 ? '12px' : '0px')};
    border-top-left-radius: ${props => (props.index % 2 === 0 ? '0px' : '12px')};
    border-bottom-left-radius: ${props => (props.index % 2 === 0 ? '0px' : '12px')};
    display: flex;
    flex-direction: column;
    padding-right: 3%;
    padding-left: 3%;
    justify-content: center;
    text-align: ${props => (props.index % 2 === 0 ? 'left' : 'right')};
   
`
const ProductContainerMobile = styled.div`
    min-height: 30vw;
    display: flex;
    flex-direction: column;
    padding-right: 15%;
    padding-left: 15%;
    padding-top: 12px;
    padding-bottom: 64px;   
`

const IconContainer = styled.div`
   
    max-height: 30vw;
    max-width: 30vw;
`

const ProductTitle = styled.div`

  @media (min-width: ${settings.bp.small.view}) {
    text-align: ${props => (props.index % 2 === 0 ? 'left' : 'right')};
}
  text-transform: uppercase;
  font-size: 48px;
  font-weight: 700;
  line-height: 100%;
  @media (min-width: 900px) {
    font-size: 50px;
  }
  @media (min-width: 1100px) {
    font-size: 64px;
  }
  @media (min-width: 1250px) {
    font-size: 80px;
  }
`

export const DisclaimerText = styled.div`
    letter-spacing: 2px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    margin-left: 32px;
    margin-right: 32px;
    text-align: center;
    margin-bottom: 16px;
    @media (min-width: ${settings.bp.small.view}) {
        margin-top: 12px;
        font-size: 16px;
        line-height: 100%;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
    }
`

const RatingContainer = styled.div`
    @media (min-width: ${settings.bp.small.view}) {
        margin-right: ${props => (props.index % 2 === 0 ? 'auto' : 'unset')};
        margin-left: ${props => (props.index % 2 === 0 ? 'unset' : 'auto')};
    }
`



const BeatTheHeatLanding = ({
    intl,
    page,
    allShopifyProducts,
    allGoogleSheetProductsRow, }) => {
    const {
        banner, // hero banner
        logo, // desktop hero image
        backgroundImage, // mobile hero image
        section1Title, // prodcut sections title
        tabs, // product sections
        educationVideoTitle, // education video title
        educationVideos, // education video
        collection1title, // review section title
        collection1Products, // review section products
    } = page

    return (
        <Fragment>
            {banner && (
                <Spacing removeSpaceTop removeSpaceBottom>
                    <HeroSlider slides={banner} />
                </Spacing>
            )}

            {logo && (
                <MobileOnly>
                    <img style={{ marginTop: '-2px', minHeight: '45vh', overflow: 'hidden', textAlign: 'center', objectFit: 'cover' }} src={logo?.localFile?.publicURL} />
                </MobileOnly>
            )}

            {logo && (
                <DesktopOnly>

                    <div style={{ width: '100%', margin: 'auto', textAlign: 'center' }}>
                        <img style={{ width: '100%' }} src={logo?.localFile?.publicURL} />
                    </div>

                </DesktopOnly>
            )}

            {section1Title && tabs?.length > 0 && (
                <Spacing>
                    {section1Title && <SectionTitle>
                        {section1Title}
                    </SectionTitle>
                    }
                    {tabs.map((tab, index) => {
                        const shopifyProduct = allShopifyProducts?.find(
                            p => p.handle === tab.product?.shopifyProduct?.handle
                        )
                        const sku = shopifyProduct?.variants?.[0]?.sku
                        return (
                            <div key={index}>
                                <DesktopOnly style={{ flexDirection: `${index % 2 === 0 ? 'row' : 'row-reverse'}`, alignItems: 'stretch', marginTop: '12px' }}>

                                    <ProductContainer index={index} color={productColors[index]}>
                                        <ProductTitle index={index} style={{ fontFamily: 'Roboto Condensed' }}>
                                            {tab.product?.title}
                                        </ProductTitle>
                                        {sku && <RatingContainer
                                            index={index}
                                            data-bv-show="inline_rating"
                                            data-bv-product-id={sku}
                                        ></RatingContainer>}
                                        {tab.product?.shopifyProduct?.handle && (
                                            <StLinkMore
                                                rounded
                                                invertTextColor
                                                center
                                                topMargin
                                                morePadding
                                                hoverColor={'#00000073'}
                                                onetwoColor
                                                responsiveFont
                                                style={{ marginRight: index % 2 === 0 ? 'auto' : '0px', marginLeft: index % 2 === 0 ? '0px' : 'auto' }}
                                            >
                                                <CustomLink
                                                    target={'_blank'}
                                                    href={`/product/${tab.product.shopifyProduct.handle}`}
                                                >
                                                    SHOP NOW
                                                </CustomLink>
                                            </StLinkMore>
                                        )}
                                    </ProductContainer>
                                    <div style={{ width: '12px' }} />
                                    {tab.icon && (
                                        <IconContainer>
                                            <img style={{ borderRadius: '12px', height: '100%' }} src={tab.icon?.localFile?.publicURL} />
                                        </IconContainer>
                                    )}
                                    <div style={{ width: '12px' }} />
                                    {tab.beforeAfterImage?.length > 0 &&
                                        <ItemImageWrapper index={index}>
                                            <img style={{ borderRadius: '12px', height: '100%', float: index % 2 === 0 ? 'left' : 'right' }} src={tab.beforeAfterImage?.[0].localFile?.publicURL} />
                                        </ItemImageWrapper>

                                    }
                                </DesktopOnly>
                                <DesktopOnly style={{ marginBottom: '123px', marginLeft: '6vw' }}>
                                    {tab.ctaText && (<DisclaimerText>{tab.ctaText}</DisclaimerText>)}
                                </DesktopOnly>
                                <MobileOnly>
                                    <ProductImages
                                        backgroundColor={productColors[index]}
                                        images={[
                                            tab.icon?.localFile?.publicURL,
                                            tab.beforeAfterImage?.[1]?.localFile?.publicURL,
                                            tab.beforeAfterImage?.[2]?.localFile?.publicURL
                                        ]}
                                        disclaimerText={tab.ctaText}
                                    />

                                    <ProductContainerMobile>
                                        <ProductTitle index={index} style={{ fontFamily: 'Roboto Condensed' }}>
                                            {tab.product?.title}
                                        </ProductTitle>
                                        {sku && <div
                                            data-bv-show="inline_rating"
                                            data-bv-product-id={sku}
                                        ></div>}
                                        {tab.product?.shopifyProduct?.handle && (
                                            <StLinkMore
                                                rounded
                                                invertTextColor
                                                center
                                                topMargin
                                                bottomMargin
                                                morePadding
                                                hoverColor={'#00000073'}
                                                onetwoColor
                                                responsiveFont
                                                style={{ margin: 'auto', marginTop: '12px', width: '100%' }}
                                            >
                                                <CustomLink
                                                    style={{ width: '100%' }}
                                                    target={'_blank'}
                                                    href={`/product/${tab.product.shopifyProduct.handle}`}
                                                >
                                                    SHOP NOW
                                                </CustomLink>
                                            </StLinkMore>
                                        )}
                                    </ProductContainerMobile>
                                </MobileOnly>
                            </div>
                        )
                    })}
                </Spacing>
            )}

            {educationVideoTitle && educationVideos?.length > 0 && (
                <Spacing
                    style={{
                        maxWidth: '1140px',
                        margin: 'auto',
                        textAlign: 'center',
                    }}
                >
                    <SectionTitle>{educationVideoTitle}</SectionTitle>
                    <VideoContainer>
                        {educationVideos.map((video, index) => {
                            return (
                                <VideoItem
                                    key={index}
                                >
                                    <a
                                        href={`/video/${video.slug}`}
                                        style={{ display: 'flex' }}
                                    >
                                        <ButtonPlay>
                                            <VideoItemImage
                                                src={
                                                    video.imageUrl
                                                }
                                            />
                                        </ButtonPlay>
                                    </a>
                                </VideoItem>
                            )
                        })
                        }
                    </VideoContainer>

                </Spacing>
            )}

            {collection1title && collection1Products?.length > 0 && (
                <Spacing style={{ backgroundColor: '#C4A298' }} removeSpaceTop>
                    <SectionTitle style={{ paddingTop: '15px', fontWeight: 500 }}>{collection1title}</SectionTitle>
                    <Testimonials testimonials={collection1Products.map((product, index) => {
                        return {
                            title: product.testimonalTitle,
                            image: product.productItem?.images[0]?.localFile?.publicURL,
                            text: product.testimonialText?.testimonialText,
                            productTitle: product.title,
                            productUrl: `/product/${product.shopifyProduct?.handle}`,
                        }
                    })} />
                </Spacing>
            )}

        </Fragment>
    )
}

export default injectIntl(BeatTheHeatLanding)
